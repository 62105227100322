import {attr, controller, target, targets} from '@github/catalyst'

@controller
class LaunchCodeElement extends HTMLElement {
  @target form: HTMLFormElement
  @targets inputs: HTMLInputElement[]
  @target result: HTMLElement
  @attr pattern: string
  @attr ajax = false

  handlePaste(event: ClipboardEvent) {
    event.preventDefault()
    const clipboardData = event.clipboardData

    if (!clipboardData) return

    const launchCode = clipboardData.getData('text')

    const launchCodeRegExp = new RegExp(`^${this.pattern}*$`)
    if (!launchCodeRegExp.test(launchCode)) return

    let lastInputElement

    for (const inputField of this.inputs) {
      const thisInputIndex = this.inputs.indexOf(inputField)
      lastInputElement = inputField
      if (!launchCode[thisInputIndex]) break
      inputField.value = launchCode[thisInputIndex]
    }

    if (this.form.checkValidity()) {
      this.submit()
    } else {
      lastInputElement?.focus()
    }
  }

  handleKeyInput(event: Event) {
    const input = event.target as HTMLInputElement
    const thisInputIndex = this.inputs.indexOf(input)
    const nextInput = this.inputs[thisInputIndex + 1]

    if (input.checkValidity()) {
      if (nextInput) {
        this.form.checkValidity() ? this.submit() : nextInput.focus()
      } else {
        if (this.form.reportValidity()) this.submit()
      }
    } else {
      input.value = ''
    }
  }

  handleKeyNavigation(event: KeyboardEvent) {
    const navigationKeys = ['Backspace', 'ArrowLeft', 'ArrowRight']
    if (!navigationKeys.includes(event.key)) return

    const input = event.target as HTMLInputElement
    const thisInputIndex = this.inputs.indexOf(input)
    const nextInput = this.inputs[thisInputIndex + 1]

    switch (event.key) {
      case 'Backspace': {
        const previousInput = this.inputs[thisInputIndex - 1]
        if (previousInput) {
          this.moveCursorToEndOfInput(previousInput)
          previousInput.value = ''
          this.result.innerHTML = ''
        }
        return
      }
      case 'ArrowLeft': {
        const previousInput = this.inputs[thisInputIndex - 1]
        if (previousInput) this.moveCursorToEndOfInput(previousInput)
        return
      }
      case 'ArrowRight': {
        if (nextInput) this.moveCursorToEndOfInput(nextInput)
        return
      }
    }
  }

  moveCursorToEndOfInput(input: HTMLInputElement) {
    const length = input.value.length
    input.focus()
    input.setSelectionRange(length, length)
  }

  async submit() {
    if (this.ajax) {
      const response = await fetch(this.form.action, {
        method: this.form.method,
        body: new FormData(this.form),
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })

      if (response.ok) {
        this.result.innerHTML = await response.text()
        this.dispatchEvent(new CustomEvent('launch-code-success', {bubbles: true}))
      } else {
        this.result.innerHTML = await response.text()
        this.dispatchEvent(new CustomEvent('launch-code-error', {bubbles: true}))
      }
    } else {
      this.form.submit()
    }
  }
}
